import React from 'react';
import './footer.scss';

import Config from '../../config/config';
import { Link } from 'react-router-dom';
import Logo from '../common/logo/logo';
import {ReactComponent as LanguageSvg} from '../../css/imgs/icon-language.svg';
import { t } from "../../translations/i18n";

import {ReactComponent as FacebookSvg} from '../../css/imgs/sns-icon-facebook.svg';
import {ReactComponent as RedditSvg} from '../../css/imgs/sns-icon-reddit.svg';
import {ReactComponent as TwitterSvg} from '../../css/imgs/sns-icon-twitter.svg';
import {ReactComponent as YoutubeSvg} from '../../css/imgs/sns-icon-youtube.svg';

import useModalState,{ModalEnum} from '../../store/modal';

const Footer = () => {

  const year = new Date().getFullYear();

  const {setActiveModal} = useModalState();

  const communityLinks = [
    {icon: <RedditSvg/>, link: "https://google.com",},
    {icon: <FacebookSvg/>, link: "https://google.com",},
    {icon: <TwitterSvg/>, link: "https://google.com",},
    {icon: <YoutubeSvg/>, link: "https://google.com",},
  ]

  return (
    <div className="footer">
      <div className="footer-sub">
        <div className="footer-logo">
          <Logo options={"white"}/>
        </div>
        <div className="footer-blocks">
          <div className="block">
            <div className="header">About</div>
            <div className="links">
              <a target="_blank" rel="noopener noreferrer" href={Config.Common.CompanyLink}>About Us</a>
              <Link to="/terms-of-use">Terms of Use</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
          <div className="block">
            <div className="header">Support</div>
            <div className="links">
              <Link to="/faq">FAQ</Link>
              <Link to="/contact-us">Contact Us</Link>
              <Link to="/feedback">Submit Feedback</Link>
              <Link to="/bug">Report a Bug</Link>
            </div>
          </div>
          <div className="block community">
            <div className="header">Community</div>
            <div className="links">
              {
                communityLinks.map((v,i) => {
                  return(
                    <a key={i} target="_blank" rel="noopener noreferrer" href={v.link}>
                      {v.icon}
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="language-button hlo" onClick={() => {setActiveModal(ModalEnum.Language)}}>        
          <span className="icon"><LanguageSvg/></span>
          <span className="text">{t("navi.language")}</span>
        </div>
        <div className="footer-legal">
          <div>Made with care by <a target="_blank" rel="noopener noreferrer" href={Config.Common.CompanyLink}>{Config.Common.Company}</a> ❤️</div>
          <div>{Config.Common.AppName} &copy; {year}</div>
        </div>
      </div>
    </div>
  );

};

export default Footer;