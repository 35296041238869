
import {Environment,EnvironmentEnum,SandboxEnvArr} from './env';

const Config = {
  Common:{
    ApiBaseUrl: "http://localhost:3085",
    ApiBaseSocket: "ws://localhost:3085",
    ApiSandboxBaseUrl: "http://localhost:3086",
    ApiSandboxBaseSocket: "ws://localhost:3086",
    AppName: "Fjorney",
    Company: "Fjorney",
    CompanyLink: "https://fjorney.com/",
    FrontEndUrl: "http://localhost:3080",
    LiveFrontEndUrl: "http://localhost:3080",
    SandboxFrontEndUrl: "http://localhost:3081",
    SupportEmailName: "mail",
    SupportEmailDomain: "fjorney.com",
  },
  Keys:{
    StripePublicKey: "pk_test_51GqiwwCrETN7D82MGHEvNP7W4p9fULfvCpnEsMyVEBmW4yozC4dNt0kAkv2eiVCTkQiJ2O3sx1kmESXwNrZdLokE00ftcmpH3o",
    StripeAnnualPriceId: "price_1HCAeUCrETN7D82MEfWzgueZ",
    StripeMonthlyPriceId: "price_1HCAemCrETN7D82MVnoMy5J7",
    GoogleAnalytics: "",
    RecaptchaPubKey: "6LecoyQpAAAAAKEU_EZGf4hcpSssn53kHxxbO8S6",

  },
};

if(SandboxEnvArr.indexOf(Environment) !== -1){
  Config.Sandbox = true;
}

if(Environment === EnvironmentEnum.Release || Environment === EnvironmentEnum.ReleaseSandbox){
  Config.Common.LiveFrontEndUrl = "https://release.fjorney.com";
  Config.Common.ApiDocsUrl = "https://docs.fjorney.com";
  Config.Common.SandboxFrontEndUrl = "https://sandbox..com";
  Config.Keys.RecaptchaPubKey = "6LdMQtchAAAAABJaGSGkjSCcfxcIjHZwJW_iRM71";
}

if(Environment === EnvironmentEnum.Release){
  Config.Common.ApiBaseUrl = "https://release-api.fjorney.com";
  Config.Common.ApiBaseSocket = "wss://release-api.fjorney.com";
  Config.Common.FrontEndUrl = "https://release-admin.fjorney.com";
}

if(Environment === EnvironmentEnum.Production || Environment === EnvironmentEnum.ProductionSandbox){
  Config.Common.LiveFrontEndUrl = "https://fjorney.com";
  Config.Common.ApiDocsUrl = "https://docs.fjorney.com";
  Config.Common.SandboxFrontEndUrl = "https://sanbox.fjorney.com";
}

if(Environment === EnvironmentEnum.Production){
  Config.Common.ApiBaseUrl = "https://api.fjorney.com";
  Config.Common.ApiBaseSocket = "wss://api.fjorney.com";
  Config.Common.FrontEndUrl = "https://admin.fjorney.com";
}

if(Environment === EnvironmentEnum.ProductionSandbox){
  // Config.Common.ApiBaseUrl = "https://api.sanbox..com";
  // Config.Common.ApiBaseSocket = "wss://api.sanbox..com";
  // Config.Common.FrontEndUrl = Config.Common.SandboxFrontEndUrl;
}

export default Config;
