import React,{useState,} from 'react';
import './article-modal.scss';
import Modal from '../../common/modal/modal';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {toast} from 'react-toastify';
import {request} from '../../../utils/request';

const ArticleModal = ({show,handleClose,callback}) => {

  const [loading,setLoading] = useState(false);
  const [title,setTitle] = useState("");
  const [article,setArticle] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    generateArticle();
  }

  const generateArticle = () => {
    let body = {title,article,}
    setLoading(true);
    request("save-new-article","/save-new-article","POST", body, {
      then: function(res){
        if(callback){ callback(); }
        if(handleClose){ handleClose(); }
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  return (
    <Modal className="article-modal" show={show} handleClose={handleClose}>
      <div>
        <div className='article-inputs'>
          <h3>Article Modal</h3>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Title</label>
              <input type="text" value={title} onChange={(e) => {setTitle(e.target.value)}}/>
            </div>
            <div>
              <label>Article</label>
              <Textarea value={article} onChange={(e) => {setArticle(e.target.value)}}></Textarea>
            </div>
            <Button type="submit" status={loading?"loading":""} value="Generate" />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ArticleModal;