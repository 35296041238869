

export const numberWithCommas = (number) => {
  if(number === undefined || number === null || isNaN(number)){
    return number;
  }
  let foo = number.toString();
  let bar = "";
  let split = foo.split(".");
  if(split.length > 1){
    foo = split[0];
    bar = "."+split[1];
  }
  let baz = foo.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return baz+bar;
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const formatCurrency = (cents) => {
  var res = cents / 100;
  res = res.toLocaleString("en-US", {style:"currency", currency:"USD"});
  return res;
}

export const findPercentageIncreaseOrDecrease = (newValue, originalValue) => {
  let increase = newValue - originalValue;
  let percentageIncrease = increase/originalValue*100
  return percentageIncrease;
}