
import {createGlobalState} from 'react-hooks-global-state';
import * as stringUtil from '../utils/string';

const {setGlobalState, useGlobalState} = createGlobalState({
  header: {menuOpen:false},
  user: (localStorage.getItem('user') !== null) ? stringUtil.tryJSONParse(localStorage.user) : null,
  snsInfo: null,
  modal: "",
})

export {setGlobalState,useGlobalState}

