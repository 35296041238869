import React from 'react';

import SnsButton from  '../../elements/sns-button/sns-button';

const LinkSnsAccounts = () => {

  return (
    <div className="link-sns-accounts">
      <h3>Link SNS Accounts</h3>
      <p>Link your social media accounts to easily log in</p>
      <SnsButton action={"link"} snsType={"google"}/>
      <SnsButton action={"link"} snsType={"facebook"}/>
    </div>
  );

};

export default LinkSnsAccounts;