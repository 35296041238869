import React from 'react';
import {ReactComponent as DashboardSvg} from '../../css/imgs/icon-dashboard.svg';
import {ReactComponent as PeopleSvg} from "../../css/imgs/icon-people.svg";
import {ReactComponent as CustomerSupportSvg} from '../../css/imgs/icon-customer-support.svg';
import {ReactComponent as FeedbackSvg} from '../../css/imgs/icon-feedback.svg';
import {ReactComponent as ToolsSvg} from '../../css/imgs/icon-tools.svg';
import {ReactComponent as NotifsSvg} from "../../css/imgs/icon-notifications.svg";
import {ReactComponent as SearchSvg} from '../../css/imgs/icon-seo.svg';
import {ReactComponent as QrCodeSvg} from '../../css/imgs/icon-qr-code-scan.svg';

import AccountStatusEnum from '../../enums/account-status';

const naviRoutes = [
  {
    "name": "dashboard-nav-item", 
    "display": "Dashboard", 
    "path": "/dashboard",
    "icon": <DashboardSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "developer-nav-item", 
    "display": "Developer", 
    "path": "/developer",
    "icon": <ToolsSvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  {
    "name": "users-nav-item", 
    "display": "Users", 
    "path": "/users",
    "icon": <PeopleSvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  // {
  //   "name": "user-roles-nav-item", 
  //   "display": "User Roles", 
  //   "desc": "Main Database",
  //   "path": "/user-roles",
  //   "icon": <UserRolesSvg/>,
  //   "minStatus": AccountStatusEnum.Staff,
  // },
  {
    "name": "search-nav-item", 
    "display": "Search", 
    "desc": "Search Database",
    "path": "/search",
    "icon": <SearchSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "ref-code-item", 
    "display": "Referrals", 
    "desc": "Site Database",
    "path": "/referrals",
    "icon": <QrCodeSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "customer-support-nav-item", 
    "display": "Customer Support", 
    "path": "/customer-support",
    "icon": <CustomerSupportSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "feedback-nav-item", 
    "display": "Feedback", 
    "desc": "Other Messages too",
    "path": "/feedback",
    "icon": <FeedbackSvg/>,
    "minStatus": AccountStatusEnum.Staff,
  },
  {
    "name": "notifs-nav-item", 
    "display": "Push Notifications", 
    "path": "/push-notifs",
    "icon": <NotifsSvg/>,
    "minStatus": AccountStatusEnum.Admin,
  },
  {
    "name": "test", 
    "display": "Test",
    "path": "/test",
    "minStatus": AccountStatusEnum.Staff,
  },
];

export default naviRoutes;