import React,{useState} from "react";
import "./user-modal.scss";

import Modal from "../../common/modal/modal";
import CarouselHeader from "../../common/carousel/carousel-header";
import Carousel from "../../common/carousel/carousel";

// import Admin from './user-modal-slides/admin';
import Activity from './user-modal-slides/activity';
import Security from './user-modal-slides/security';
import History from './user-modal-slides/history';
import Profile from './user-modal-slides/profile';


const UserModal = ({viewUser,show,handleClose,refresh,}) => {

  const [slide,setSlide] = useState(1);

  return (
    <Modal 
      className="user-modal" 
      show={show} 
      handleClose={() => {handleClose()}}>
      <div>
        <CarouselHeader className="entry-options hlo" slideTo={slide} forceResize={show}>
          <div onClick={() => { setSlide(1);}}>Profile</div>

          <div onClick={() => { setSlide(2);}}>Activity</div>
          <div onClick={() => { setSlide(3);}}>Security</div>
          <div onClick={() => { setSlide(4);}}>History</div>
          <div onClick={() => { setSlide(5);}}>Roles</div>
          {/* <div onClick={() => { setSlide(7);}}>Admin</div> */}
        </CarouselHeader>
        <Carousel slideTo={slide}>
          <div><Profile viewUser={viewUser} /></div>
          <div><Activity viewUser={viewUser} /></div>
          <div><Security viewUser={viewUser} /></div>
          <div><History viewUser={viewUser} /></div>
          <div>Roles</div>
          {/* <div><Admin viewUser={viewUser} handleClose={handleClose} refresh={refresh} /></div>  */}
        </Carousel>
      </div>
    </Modal>
  );
};

export default UserModal;