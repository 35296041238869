import React from 'react';
import './my-account.scss';

// import AccountType from './account-type';
// import Notifications from './notifications';
// import PaymentDetails from './payment-details';
import DangerZone from './danger-zone';
import ProfileDetails from './profile-details';
import LinkSnsAccounts from './link-sns-accounts';
import AccountSecurity from './account-security';


const MyAccount = () => {

  return (
    <div className="my-account">
      <h1>My Account</h1>
      
      <ProfileDetails />
      {/* <AccountType /> */}
      {/* <PaymentDetails /> */}
      <AccountSecurity />
      <LinkSnsAccounts />
      {/* <Notifications /> */}
      <DangerZone />

    </div>
  );

};

export default MyAccount;