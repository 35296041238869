import {useGlobalState} from './store';


const useModalState = () => {
  
  const [activeModal, setActiveModal] = useGlobalState('modal'); 

  return {activeModal, setActiveModal};
}

export default useModalState;

export const ModalEnum = {
  Language: "language",
}