import React,{useState,useEffect} from 'react';
import './search.scss';
import Carousel from '../../common/carousel/carousel';
import CarouselHeader from '../../common/carousel/carousel-header';
import Table from '../../elements/table/table';
import Textarea from 'react-textarea-autosize';
import Button from '../../elements/button/button';
import {toast} from 'react-toastify';
import {request} from '../../../utils/request';
import {KlineIntervalEnum} from '../../../enums/kline-interval';
import CopyInput from '../../elements/copy-input/copy-input';
import ArticleModal from './article-modal';
import ModifyArticleModal from './modify-article-modal';
import ConfirmModal from '../../common/modal/confirm-modal';
import Modal from '../../common/modal/modal';
import Config from '../../../config/config';

const Search = () => {

  const [loading,setLoading] = useState(false);
  const [confirmShow,setConfirmShow] = useState(false);
  const [show,setShow] = useState(false);
  const [showSitemap,setShowSitemap] = useState(false);
  const [showEditModal,setEditModalShow] = useState(false);
  const [rowValue,setRowValue] = useState(null);
  const [initKeyword,setInitKeyword] = useState("");
  const [loadingRefreshId,setLoadingRefreshId] = useState(0);
  const [results,setResults] = useState(null);
  const [keywords,setKeywords] = useState("");
  const [slide,setSlide] = useState(1);
  const [refreshInc,setRefreshInc] = useState(1);
  const [autoUpdateWords,setAutoUpdateWords] = useState(false);
  const [siteMapStr,setSiteMapStr] = useState("");

  const [editId,setEditId] = useState(0);
  const [vol,setVol] = useState("");
  const [lang,setLang] = useState("");

  useEffect(() => {
    if(autoUpdateWords && loadingRefreshId === 0){
      let found = false;
      let id = 0;
      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        let newId = result.keywordsId;
        let updatedDate = result.keywordsUpdatedDate;
        if(updatedDate === null){ 
          id = newId;
          found = true;
          break; 
        }
        let time = new Date().getTime() - KlineIntervalEnum.MonthOne;
        updatedDate = new Date(updatedDate).getTime();
        if(time > updatedDate){
          id = newId;          
          found = true;
          break;
        }
      }
      refreshKeyword(id,true);
      setAutoUpdateWords(found);
    }
  },[results,loadingRefreshId,autoUpdateWords])


  const columns = [
    {table: "keywords",value: "Del", type:"date", ignore:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let id = v["keywordsId"];
        return(
          <div className="buttons left">
            <Button status="delete" onClick={() => {
              setConfirmShow(id);
              // deleteKeyword(id);
            }}></Button>
          </div>
        )
      },  
    },
    {table: "keywords",value: "id", index:true, hide:true},
    {table: "keywords",value: "keyword", index:true, ignoreRowClick:true,
      custom: ({v,cv}) => <CopyInput display={""} value={(v[cv])}/>
    },
    {table: "keywords",value: "volume",index:true, ignoreRowClick:true,
      custom: ({v,cv}) => { 
        let id = v["keywordsId"];
        let value = editId === id?vol:v[cv]
        return(
          <form className='volume' onSubmit={(e) => {e.preventDefault();editKeyword(id,vol,lang)}}>
            <input 
              onDoubleClick={() => {
                setVol(v["keywordsVolume"]);
                setLang(v["keywordsLanguage"]);
                setEditId(id)
              }}
              readOnly={editId !== id}
              type="text" value={value}  onChange={(e) => {setVol(e.target.value)}}/>
          </form>
        )
      },  
    },
    {table: "keywords",value: "language",index:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let id = v["keywordsId"];
        let value = editId === id?lang:v[cv]
        return(
          <form className='language' onSubmit={(e) => {e.preventDefault();editKeyword(id,vol,lang)}}>
            <input 
              onDoubleClick={() => {
                setVol(v["keywordsVolume"]);
                setLang(v["keywordsLanguage"]);
                setEditId(id)
              }}
              readOnly={editId !== id}
              type="text" value={value} onChange={(e) => {setLang(e.target.value)}} />
          </form>
        )
      },  
    },
    {table: "keywords",value: "updatedDate", type:"date", index:true,  ignoreRowClick:true,},
    {table: "keywords",value: "createdDate", type:"date", index:true, defaultOrder:"DESC", ignoreRowClick:true,},
    {table: "keywords",value: "action", type:"date", ignore:true, ignoreRowClick:true,
      custom: ({v,cv}) => {
        let id = v["keywordsId"];
        let keyword = v['keywordsKeyword'];
        return(
          <div className="buttons right">
            {editId !== id?<>
              <Button status="edit" onClick={() => {
                setVol(v["keywordsVolume"]);
                setLang(v["keywordsLanguage"]);
                setEditId(id);
              }}></Button>
            </>:null}

            {editId === id?<>
              <Button status="close" onClick={() => {
                setEditId(0);
              }}></Button>
              <Button status="save" onClick={() => {
                editKeyword(editId,vol,lang);
              }}></Button>
            </>:null}

            <Button status={loadingRefreshId === id?"loading":"refresh"} onClick={() => {
              refreshKeyword(id);
            }}></Button>

            {/* <Button parentClassName={"new-article"} status="add" onClick={() => {
              setInitKeyword(keyword);
              setShow(true);
            }}>Article</Button> */}
          </div>
        )
      },  
    },
  ]


  const sideItems = [
    {display: "All", conditions:[]},
    {display: "Published", conditions:[{
      column:"published", table:"articles", action: "=",value: 1,
    }]},
    {display: "Un-published", conditions:[{
      column:"published", table:"articles", action: "=",value: 0,
    }]},
  ];
  const articleColumns = [
    {table: "articles",value: "id", index:true, hide:true},
    {table: "articles",value: "uid", index:true, hide:true},
    {table: "articles",value: "title",  
      custom: ({v,cv}) => <div className='title-div'>{v[cv]}</div>
    },
    {table: "articles",value: "metaDesc", hide:true },
    {table: "articles",value: "metaKeywords", hide:true },
    {table: "articles",value: "json", hide:true },
    {table: "articles",value: "article", hide:true },
    {table: "articles",value: "author", hide:true },
    {table: "articles",value: "imageUrl", hide:true },
    {table: "articles",value: "imageText", hide:true },
    {table: "articles",value: "urlPath", hide:true },
    {table: "articles",value: "published", hide:true, index:true,
      custom: ({v,cv}) => <div>{v[cv]?"True":"False"}</div>
    },
    {table: "articles",display:"Pub Date",value: "publishedDate", type:"date", index:true, },
    {table: "articles",display:"Date",value: "createdDate", type:"date", index:true, defaultOrder:"DESC"},
  ]

  const joinOptions = [
    // {joinType:"LEFT JOIN",leftTable:"autoMessage",rightTable:"users",leftColumn:"userId",rightColumn:"id",},
  ]

  const saveKeywords = (keywords) => {
    var lines = keywords.split('\n');
    setLoading(true);
    request("keywords","/keywords","POST", {keywords:lines}, {
      then: function(res){
        setKeywords("");
        setRefreshInc(refreshInc + 1);
      },
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const editKeyword = (id,vol,lang) => {
    setEditId(0);
    setLoading(true);
    request("keywords","/keywords","PATCH", {id,vol,lang}, {
      then: function(res){setRefreshInc(refreshInc + 1);},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ setLoading(false);}
    });
  }

  const deleteKeyword = (id) => {
    request("keywords","/keywords","DELETE", {id}, {
      then: function(res){setRefreshInc(refreshInc + 1);},
      catch: function(err){ toast.error(err.message);},
      finally: function(){ }
    });
  }

  const refreshKeyword = (id,auto) => {
    let time = auto?10000:1;
    if(loadingRefreshId === id){
      return;
    }
    console.log("refreshKeyword",id);
    setTimeout(() => {
      console.log("refreshKeyword go",id);
      setLoadingRefreshId(id);
      request("keywords-"+id,"/keyword-refresh","POST", {id}, {
        then: function(res){setRefreshInc(refreshInc + parseInt(id));},
        catch: function(err){ toast.error(err.message);},
        finally: function(){setLoadingRefreshId(0);}
      });
    },time)
  }

  return (
    <div className="search-comp">
      <h3>Search Engine Optimizer</h3>

      <CarouselHeader slideTo={slide}>
        <div onClick={() => {setSlide(1)}}>Articles</div>
        <div onClick={() => {setSlide(2)}}>Key Words</div>
      </CarouselHeader>

      <Carousel slideTo={slide}>
        <div>
          <Table 
            displayName="Articles"
            name="ArticlesTable"
            database={"Search"}
            sideItems={sideItems}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              setRowValue(value);
              setEditModalShow(true);
            }}
            refreshInc={refreshInc}
            columns={articleColumns}
            buttons={[
              { 
                status: "hiding",
                text:"SiteMap",
                onClick: () => {
                  setShowSitemap(true);
                }
              },
              { 
                status: "add",
                text:"New Article",
                onClick: () => {
                  setRowValue(null);
                  setShow(true);
                }
              },
            ]}
            bottomLeftButtons={[
              { text:"Refresh", status:"refresh", onClick:() => {setRefreshInc(refreshInc + 1)}, }
            ]}
            returnResultsFunc={(results) => {
              let newSiteMapStr = "";
              for (let i = 0; i < results.length; i++) {
                const result = results[i];
                if(result.articlesPublished === 1){
                  if(newSiteMapStr !== ""){newSiteMapStr += `\n`}
                  newSiteMapStr += `<url><loc>${Config.Common.LiveFrontEndUrl}/article/${result.articlesUid}/${result.articlesUrlPath}</loc></url>`
                }
              }
              setSiteMapStr(newSiteMapStr);
              setResults(results);
            }}
            limit={20}
            />
        </div>
        <div>
          <div className='box'>
            <Textarea placeholder='Keywords...' value={keywords} onChange={(e) => {setKeywords(e.target.value)}}></Textarea>
            <Button status="add" onClick={() => {
              saveKeywords(keywords)
            }}>Add Keywords</Button>
          </div>
          <div className='line'></div>
          <Table 
            displayName="Keywords"
            name="KeywordsTable"
            database={"Search"}
            sideItems={[]}
            sideItemActiveClickFunc={(item) => {
              // console.log("item",item);
            }}
            joinOptions={joinOptions}
            rowOnClickFunc={(value) => {
              // setRowValue(value);
              // setEditModalShow(true);
            }}
            refreshInc={refreshInc}
            columns={columns}
            buttons={[
              { 
                status: autoUpdateWords?"stop":"play",
                text:"Update Words",
                onClick: () => {
                  setAutoUpdateWords(!autoUpdateWords);
                }
              },
            ]}
            bottomLeftButtons={[
              { text:"Refresh", status:"refresh", onClick:() => {setRefreshInc(refreshInc + 1)}, }
            ]}
            returnResultsFunc={(results) => {
              setResults(results);
            }}
            limit={100}
            />
        </div>
      </Carousel>

      <ArticleModal 
        initKeyword={initKeyword}
        show={show} 
        handleClose={() => {setShow(false)}}
        callback={() => {
          setRefreshInc(refreshInc + 1);
        }}
        />

      <ModifyArticleModal 
        value={rowValue}
        show={showEditModal} 
        handleClose={() => {setEditModalShow(false)}}
        callback={() => {
          setRefreshInc(refreshInc + 1);
        }}
        />

      <Modal className={"sitemap-modal"} show={showSitemap} handleClose={() => {setShowSitemap(false)}}>
        <div>
          <h2>Sitemap Modal</h2>
          <CopyInput display={""} value={siteMapStr} textarea={true} />
        </div>
      </Modal>
      
      <ConfirmModal show={confirmShow} handleClose={(value) => {
          if(value){
            deleteKeyword(confirmShow);
          }
          setConfirmShow(false);
        }}>
        <div>
          Are you sure you want to delete this?
        </div>
      </ConfirmModal>
      
    </div>
  );

};

export default Search;