import {useEffect, useState } from 'react';
import {useGlobalState} from './store';


import {clone} from '../utils/clone';

const useHeaderState = () => {
  
  const [header, setHeader] = useGlobalState('header'); 
  const [checkHeader,setCheckHeader] = useState(false);

  const toggleMenu = () => {
    let headerClone = clone(header);
    headerClone.menuOpen = !headerClone.menuOpen;
    document.body.style.overflow = (headerClone.menuOpen)?"hidden":"";
    setHeader(headerClone);
  }

  const popstateListener = (href) => {
    setCheckHeader(true)
  };

  useEffect(() => {
    window.addEventListener('popstate', popstateListener);
    return () => {
      window.removeEventListener('popstate', popstateListener, false);
    }
  },[]);

  useEffect(() => {
    if(checkHeader){
      if(header.menuOpen){
        let headerClone = clone(header);
        headerClone.menuOpen = !headerClone.menuOpen
        document.body.style.overflow = (headerClone.menuOpen)?"hidden":"";
        setHeader(headerClone);
      }
      setCheckHeader(false)
    }
  },[checkHeader,header,setHeader])


  return {header, toggleMenu};
}

export default useHeaderState;