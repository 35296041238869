import React from 'react';
import './push-notifs.scss';

const PushNotifs = () => {

  return (
    <div className="push-notifs-comp">
      <h1>Push Notifs</h1>
    </div>
  );

};

export default PushNotifs;