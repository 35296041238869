

const Settings = {
  TopNavi: false,
  SideNavi: true,
  StickyNavi: true,
  Footer: false,
};


export default Settings;
